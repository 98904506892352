import React from "react";
import { Avatar, Button, makeStyles, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import cx from "classnames";
import appStoreBadge from "../../images/app-store-badge.svg";
import googlePlayBadge from "../../images/google-play-badge.png";
import itchBadge from "../../images/itch-badge.svg";
import { Link } from "gatsby-theme-material-ui";

const useStyles = makeStyles(theme => ({
  avatar: {
    boxShadow: `0px 0px 0px 16px ${theme.palette.secondary.light}`,
    height: 256,
    width: 256
  },
  flexColumnCenter: {
    alignItems: "center",
    display: "flex",
    flex: 2,
    flexDirection: "column"
  },
  flexFill: {
    flex: 1
  },
  image: {
    height: 128,
    width: 128
  },
  imageContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: 24
    }
  },
  marginBottom: {
    marginBottom: 8
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      flexDirection: "column"
    }
  },
  storeLinkItem: {
    height: 64,
    margin: 8,
    width: "auto"
  },
  storeLinks: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row"
  },
  textWhiteCentered: {
    color: "#fff",
    textAlign: "center"
  },
  button: {
    color: "#fff"
  }
}));

function GameItem({
  description,
  itchLink,
  googlePlayLink,
  image,
  iTunesLink,
  subtitle,
  title,
  to
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <Avatar alt={title} src={image} className={classes.avatar} />
      </div>
      <div className={classes.flexColumnCenter}>
        <Typography
          className={cx(classes.textWhiteCentered, classes.marginBottom)}
          variant="h6"
        >
          {title}
        </Typography>
        <Typography
          className={cx(classes.textWhiteCentered, classes.marginBottom)}
          variant="body1"
        >
          {subtitle}
        </Typography>
        <Typography
          className={cx(classes.textWhiteCentered, classes.flexFill)}
          variant="caption"
        >
          {description}
        </Typography>
        <div className={cx(classes.storeLinks)}>
          {googlePlayLink && (
            <a href={googlePlayLink} target="_blank">
              <img className={classes.storeLinkItem} src={googlePlayBadge} />
            </a>
          )}
          {iTunesLink && (
            <a href={iTunesLink} target="_blank">
              <img className={classes.storeLinkItem} src={appStoreBadge} />
            </a>
          )}
          {itchLink && (
            <a href={itchLink} target="_blank">
              <img className={classes.storeLinkItem} src={itchBadge} />
            </a>
          )}
          {to && (
            <Button
              className={classes.button}
              variant={"outlined"}
              component={Link}
              to={to}
            >
              Details
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

GameItem.propTypes = {
  description: PropTypes.string.isRequired,
  googlePlayLink: PropTypes.string,
  iTunesLink: PropTypes.string,
  image: PropTypes.string.isRequired,
  itchLink: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string
};

export default GameItem;
