import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import GameItem from "../../components/games/GameItem";
import auguste from "../../images/auguste.png";
import popularity from "../../images/logo-popularity.svg";
import dinerDerby from "../../images/diner_derby.png";
import math from "../../images/math.png";
import rainbowFarts from "../../images/rainbow-farts.png";
import SEO from "../../components/seo";

const useStyles = makeStyles(theme => ({
  flexColumnCenter: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  maxWidth: {
    maxWidth: 960
  },
  paddingAll: {
    padding: "70px 15px"
  },
  root: {
    backgroundColor: theme.palette.secondary.main,
    flex: 1
  },
  textBlockContent: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: 960,
    width: "100%"
  }
}));

function Games() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SEO title={"Games"} />
      <div className={classNames(classes.paddingAll)}>
        <div className={classes.textBlockContent}>
          <div className={classes.flexColumnCenter}>
            <GameItem
              to={"/games/popularity"}
              image={popularity}
              title="Popularity"
              subtitle="Erschaffe dein eigenes soziales Netzwerk und verändere die Welt"
              description="Jede Entscheidung ist bedeutungsvoll. Manage dein Personal, umgarne deine Inestoren und halte deine Community am Leben. Manipuliere deine User, Wahlen und die Wirtschaft, oder erschaffe eine bessere Zukunft."
            />
          </div>
        </div>
      </div>
      <div className={classNames(classes.paddingAll)}>
        <div className={classes.textBlockContent}>
          <div className={classes.flexColumnCenter}>
            <GameItem
              image={rainbowFarts}
              title="Rainbow Farts"
              subtitle="Lieben Sie den Geruch überiechender Luft am Morgen? Dann ist dieses Spiel für SIE!"
              description="In diesem Hardcore-Action Non-Platformer ist Ihr Ziel der endlose Himmer über Ihnen. Managen Sie Ihre Energie, indem Sie in der Luft etwas Pizza essen, während Sie Ihren Feinden mit Ihrem bösen Winden ausweichen."
              googlePlayLink="https://play.google.com/store/apps/details?id=games.vollkorn.rainbowfarts"
            />
          </div>
        </div>
      </div>
      <div className={classNames(classes.paddingAll)}>
        <div className={classes.textBlockContent}>
          <div className={classes.flexColumnCenter}>
            <GameItem
              image={math}
              title="Mathe Müsli"
              subtitle="Kopfrechnen bis die Schüssel leer ist!"
              description="In diesem kleinen Kopfrechentrainer kannst du deine Schnelligkeit auf die Prüfung stellen. Wähle die Rechenarten und Zahlenräume und rechne was das Zeug hält!"
              googlePlayLink="https://play.google.com/store/apps/details?id=games.vollkorn.qegmath"
            />
          </div>
        </div>
      </div>
      <div className={classNames(classes.paddingAll)}>
        <div className={classes.textBlockContent}>
          <div className={classes.flexColumnCenter}>
            <GameItem
              image={auguste}
              title="Auguste"
              description="Auguste unterstützt das gemeinsame Hirnleistungstraining für Menschen mit Demenzerkrankungen. Gemeinsam mit der Alzheimer Gesellschaft getestet, entwickelt und veröffentlicht. Zur Auswahl stehen fünf verschiedene Spiele. Durch die Möglichkeit des Uploads von eigenen Bildern können Sie Auguste auf den individuellen Erfahrungsschatz der einzelnen Person anpassen."
              googlePlayLink="https://play.google.com/store/apps/details?id=games.vollkorn.auguste"
              iTunesLink="https://itunes.apple.com/us/app/linksys/id1439090604#?platform=ipad"
            />
          </div>
        </div>
      </div>
      <div className={classNames(classes.paddingAll)}>
        <div className={classes.textBlockContent}>
          <div className={classes.flexColumnCenter}>
            <GameItem
              image={dinerDerby}
              title="Diner Derby"
              description="Versuche das meiste Essen in deine Richtung zu schaufeln um deine Freunde zu schlagen. Mit Unterstützung für bis zu vier Kontrollern der ideale Zeitvertreib bis die echte Pizza da ist."
              itchLink="https://vollkorn-games.itch.io/diner-derby"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Games;
